@import "../../assets/sass/core/functions";
@import "../../assets/sass/core/animations";
@import "../../assets/sass/core/settings";
@import "../../assets/sass/vendor/breakpoint";

.hero {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .parallax {
    height: 90vh;
    margin: 0;
    padding: 0;
    width: 90vw;

    li {
      height: 100%;
      width: 100%;

      &.top-container {
        animation: fade-in 2s ease-in-out 0s forwards;
        opacity: 0;
        z-index: 2;
      }
      &.title-container {
        animation: fade-in 2s ease-in-out 2s forwards;
        opacity: 0;
        z-index: 2;
      }
      &.bottom-container {
        animation: fade-in 2s ease-in-out 4s forwards;
        opacity: 0;
        z-index: 2;
      }
      &.oval-container {
        animation: fade-in 2s ease-in-out 2s forwards;
        opacity: 0;
        z-index: 1;
      }
      &.splash-container {
        animation: fade-in 2s ease-in-out 1.5s forwards;
        opacity: 0;
        z-index: 0;
      }
    }
  }

  .top,
  .bottom {
    color: $color-pink;
    font-family: $font-fira-mono;
    font-size: 1rem;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    text-transform: lowercase;
    top: 50%;
    white-space: nowrap;

    @include breakpoint($screen-sm-min) {
      font-size: 2rem;
    }
    @include breakpoint($screen-md-min) {
      font-size: 2.5rem;
    }
    @include breakpoint($screen-lg-min) {
      font-size: 3rem;
    }
  }
  .top {
    transform: translateX(-50%) translateY(-160%);

    @include breakpoint($screen-xs-min) {
      transform: translateX(-170%) translateY(-165%);
    }
    @include breakpoint($screen-sm-min) {
      transform: translateX(-170%) translateY(-145%);
    }
  }
  .bottom {
    transform: translateX(-50%) translateY(80%);

    @include breakpoint($screen-xs-min) {
      transform: translateX(-30%) translateY(100%);
    }
    @include breakpoint($screen-sm-min) {
      transform: translateX(-30%) translateY(70%);
    }
  }
  .title {
    font-size: 3rem;
    left: 50%;
    line-height: 1;
    opacity: 0.8;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;

    @include breakpoint($screen-xs-min) {
      font-size: 4rem;
    }
    @include breakpoint($screen-sm-min) {
      font-size: 6rem;
    }
    @include breakpoint($screen-md-min) {
      font-size: 8rem;
    }
    @include breakpoint($screen-lg-min) {
      font-size: 9rem;
    }
  }
  .oval {
    left: 50%;
    height: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    transform: translateX(-60%) translateY(-70%);
    width: 100%;
    z-index: 0;
  }
  .splash {
    left: 50%;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 50%;
    z-index: 0;
  }
}
