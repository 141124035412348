// ========================================
//
// @file _animations.scss
// File containing all CSS animation functions
//
// ========================================

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
