// ========================================
//
// @file _typography.scss
// Base level typography, fonts, colors, sizes
//
// ========================================

p {
	&:last-child {
	  margin-bottom: 0;
	}
}

a {
  transition: 0.2s ease-in-out;
  color: $color-gray;
  position: relative;
  text-decoration: none;

  &:before {
    background: $color-pink;
    bottom: 0;
    content: '';
    height: 6px;
    left: 0;
    position: absolute;
    transition: 0.2s ease-in-out;
    width: 100%;
    z-index: -1;
  }

  &:hover {
    color: $color-white;

    &:before {
      height: 19px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-pink;
  font-family: $font-montserrat;
  margin: 0;

  a {
    color: inherit;
  }
}

h1 {
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;

  @include breakpoint($screen-xs-min) {
    font-size: 4rem;
  }
  @include breakpoint($screen-sm-min) {
    font-size: 6rem;
  }
  @include breakpoint($screen-md-min) {
    font-size: 8rem;
  }
}

h2 {
  font-size: 1rem;
  font-family: $font-fira-mono;
  font-weight: 400;
  text-transform: lowercase;

  @include breakpoint($screen-sm-min) {
    font-size: 1.5rem;
  }
}

ul {
  list-style: none;

  li {
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ol {
  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  background: white;
  border-radius: 2px;
  border: none;
  box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.1);
  display: inline-block;
  outline: none;
  padding: 20px 40px;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }
  &:active {
    transform: translateY(2px);
  }

  &.active {
    background: $color-pink;
    color: $color-white;
  }
}
