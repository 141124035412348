// ========================================
//
// @file _base.scss
// Base level tags and styles
//
// ========================================

::selection {
  background: lighten($color-blue, 10%);
  color: $color-white;
}

::-moz-selection {
  background: lighten($color-blue, 10%);
  color: $color-white;
}

html {
  box-sizing: border-box;
}

body {
  background: $color-fade;
  color: $color-gray;
  font-family: $font-fira-mono;
  font-size: rem(16px);

  @include breakpoint($screen-xs-min) {
    font-size: rem(18px);
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

img {
  display: block;
  height: auto;
  margin: 0;
  max-width: 100%;
  transition: 0.4s;
}

p {
  margin: 1.5rem 0;
  line-height: 1.5rem;
}
