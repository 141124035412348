@import "../../assets/sass/core/functions";
@import "../../assets/sass/core/settings";
@import "../../assets/sass/vendor/breakpoint";

.bio {
  .info {
    display: flex;
    flex-wrap: wrap;

    .personal,
    .contact {
      > ul {
        line-height: 1.5rem;
        margin-bottom: 1.5rem;
        padding: 0;
      }
    }

    strong {
      font-weight: 700;
    }
  }

  .switcher {
    margin: 30px 0;
    padding: 0;
    text-align: center;

    li {
      display: inline-block;
      margin: 10px;
    }
  }

  .description {
    display: none;
    margin: 0 auto;
    width: 100%;

    @include breakpoint($screen-sm-min) {
      width: 70%;
    }
    @include breakpoint($screen-md-min) {
      width: 60%;
    }
    @include breakpoint($screen-lg-min) {
      width: 50%;
    }

    &.active {
      display: block;
    }
  }

  .story {
    position: relative;

    .oval {
      left: 0;
      opacity: 0.6;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      z-index: -1;
    }
  }
}
