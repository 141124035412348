// ========================================
//
// @file _settings.scss
// Fonts, colors, breakpoints and other various variables
//
// ========================================

@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;700&family=Montserrat:wght@800&display=swap');

// - - - - - - - - - - - - - - - - - - - -
// Base font size
// - - - - - - - - - - - - - - - - - - - -
$base-font-size: 16px;

// - - - - - - - - - - - - - - - - - - - -
// Colors
// - - - - - - - - - - - - - - - - - - - -
$color-white: white;
$color-fade: #E6F1F0;
$color-black: black;
$color-pink: #F50057;
$color-blue: #3bd7fb;
$color-gray: #37474F;

// - - - - - - - - - - - - - - - - - - - -
// Fonts
// - - - - - - - - - - - - - - - - - - - -
$font-montserrat: 'Montserrat', sans-serif;
$font-fira-mono: 'Fira Mono', monospace;

// - - - - - - - - - - - - - - - - - - - -
// Grid
// - - - - - - - - - - - - - - - - - - - -
$screen-xs: 420px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$screen-xs-min: rem($screen-xs);
$screen-sm-min: rem($screen-sm);
$screen-md-min: rem($screen-md);
$screen-lg-min: rem($screen-lg);

$screen-xs-max: rem(($screen-sm - 1));
$screen-sm-max: rem(($screen-md - 1));
$screen-md-max: rem(($screen-lg - 1));
$screen-lg-max: rem($screen-lg);

$grid-columns: 12;
$grid-gutter: 30px;
$grid-gutter-width: rem(($grid-gutter / 2));

$container-xs: rem(($screen-xs + $grid-gutter));
$container-sm: rem(($screen-sm + $grid-gutter));
$container-md: rem(($screen-md + $grid-gutter));
$container-lg: rem(($screen-lg + $grid-gutter));
